import { useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./Slider.css"; // import CSS file for styling

function Sidebar(props) {
  const [showOptions, setShowOptions] = useState(false);
  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };
  const navigate = useNavigate();
  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <img
          src={props.Img}
          height="70px"
          style={{ filter: "drop-shadow(0px 5px 3px rgb(40, 40, 40))" }}
        />
      </div>
      <div className="sidebar-links">
        <ul>
          <li>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => {
                props.event();
                navigate("/");
              }}
            >
              <ListItemText primary={"About"} />
            </ListItemButton>
          </li>

          <li style={{ display: "flex", alignItems: "" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "auto auto",
              }}
              onClick={toggleOptions}
            >
              Products <ArrowDropDownIcon />
            </div>
          </li>
          {showOptions && (
            <div className="sidebar-options">
              <li>
                <ListItemButton
                  sx={{ textAlign: "center" }}
                  onClick={() => {
                    props.event();
                    window.open("https://savemom.in/");
                  }}
                >
                  <ListItemText primary={"Savemom"} />
                </ListItemButton>
              </li>
              <li>
                <ListItemButton
                  sx={{ textAlign: "center" }}
                  onClick={() => {
                    props.event();
                  }}
                >
                  <ListItemText primary={"AlloHMS"} />
                </ListItemButton>
              </li>
              <li>
                <ListItemButton
                  sx={{ textAlign: "center" }}
                  onClick={() => {
                    props.event();
                  }}
                >
                  <ListItemText primary={"Allobaby"} />
                </ListItemButton>
              </li>
            </div>
          )}
          <li>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => {
                navigate("/news");
                props.event();
              }}
            >
              <ListItemText primary={"News"} />
            </ListItemButton>
          </li>
          <li>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => {
                navigate("/Awards");
                props.event();
              }}
            >
              <ListItemText primary={"Awards"} />
            </ListItemButton>
          </li>
          <li>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => {
                window.open("https://jioviohealthcare.medium.com/");
                props.event();
              }}
            >
              <ListItemText primary={"Blog"} />
            </ListItemButton>
          </li>
          <li>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={()=>{
                props.event();
                props.scrollRef()}}
            >
              <ListItemText primary={"Contact"} />
            </ListItemButton>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
