// import { useEffect } from 'react';

// const ScrollToTop = () => {
//   useEffect(() => {
//     const onRouteChange = () => {
//       window.scrollTo(0, 0);
//     };

//     // Add a listener to the 'popstate' event, which is triggered when the route changes
//     window.addEventListener('popstate', onRouteChange);

//     // Cleanup the listener when the component is unmounted
//     return () => {
//       window.removeEventListener('popstate', onRouteChange);
//     };
//   }, []);

//   return null;
// };

// export default ScrollToTop;
// ScrollToTop.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default ScrollToTop;
