import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea,Grid } from '@mui/material';

export default function ActionAreaCard(props) {
  return (
    <Grid item xs="auto" style={{paddingTop:"10px"}}>
        <div style={{width:"22pc",paddingTop:"0"}}>
          <CardActionArea>
            <div  style={{backgroundColor:"white",width:"fit-content",padding:"20px 20px",margin:"10px auto",borderRadius:"50px",color:"#F16071"}}>
                {props.Icone}
            </div>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" color={props.color}>
                {props.sub}
              </Typography>
              <Typography variant="body2" color={props.color}>
              {props.content}
              </Typography>
            </CardContent>
          </CardActionArea>
        </div>
 </Grid >
  );
}