import { Grid } from "@mui/material";
import Productscard from "./Productscard";
import Img1 from "./img/savemom.jpg";
import Img2 from "./img/allolab.jpg";
import CardMedia from "@mui/material/CardMedia";
import Img4 from "./img/allocare.jpg";
import Img3 from "./img/allobaby.jpg";
import Img5 from "./img/Logo _SVU.jpg";
import Img6 from "./img/DST-logo.jpg";
import Img7 from "./img/birac_logo.jpg";
import Card from "@mui/material/Card";

function Products(props) {
  const url = "https://savemom.in/";
  return (
    <div style={{ marginTop: "5pc" }} ref={props.refProp}>
      <h2 style={{ fontWeight: "100" }}>Our Products</h2>
      <Grid
        container
        spacing={9}
        sx={{ justifyContent: "center", padding: "0", marginTop: "50px" }}
      >
        <Productscard
          Img={Img1}
          heading="Savemom"
          body="Savemom is an IOT based maternal health care solution that promises to effectively address the malady of poor maternal healthcare in the remote regions of India."
          url={url}
        />
        <Productscard
          Img={Img2}
          heading="Allolab"
          body="Allolab is the “healthcare product that analyses to give a proper health checkup for schools/corporates”. The platform is about to be preventive, every disease can be cured easily if proper checkup has done regularly."
        />
        <Productscard
          Img={Img3}
          heading="Allobaby"
          body="Allobaby is a platform designed for childcare developed with web and mobile app. Web app is for monitoring the backend process. Mobile app is for accessing the child data uncommonly intended for women."
        />
        <Productscard
          Img={Img4}
          heading="Allodoc"
          body="Allodoc is a platform designed to maintain health records digitally by the employees, this reduces health check-up time whenever employees visits Doctor .The mobile application used by employees in order to view  the regular health check-ups  done by Human Resources."
        />
      </Grid>
      <Grid
        container
        spacing={9}
        sx={{
          justifyContent: "center",
          padding: "25px",
          marginTop: "50px",
          gap: "50px",
          backgroundColor: "#f16070",
          alignItems: "center",
        }}
      >
        <Card sx={{ maxWidth: 300, marginLeft: "20px" }}>
          <CardMedia
            component="img"
            height="109px"
            image={Img7}
            alt="green iguana"
          />
        </Card>
        <Card sx={{ maxWidth: 345, marginLeft: "50px" }}>
          <CardMedia
            component="img"
            height="auto"
            image={Img6}
            alt="green iguana"
          />
        </Card>
        <Card sx={{ maxWidth: 345, marginLeft: "50px" }}>
          <CardMedia
            component="img"
            height="auto"
            image={Img5}
            alt="green iguana"
          />
        </Card>
      </Grid>
    </div>
  );
}
export default Products;
