// import * as React from "react";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import Typography from "@mui/material/Typography";
// import { Button, CardActionArea, CardActions } from "@mui/material";

// export default function MultiActionAreaCard(props) {
//   const openInNewTab = (url) => {
//     window.open(url, "_blank", "noopener,noreferrer");
//   };
//   const url =
//     "https://yourstory.com/herstory/2023/01/man-changing-face-women-cricket?utm_pageloadtype=scroll";
//   return (
//     <Card sx={{ maxWidth: 300 }} style={{ pointerEvents: "none" }}>
//       <CardActionArea style={{ height: "90%" }}>
//         <CardMedia
//           component="img"
//           height="200"
//           image={props.Img}
//           alt="green iguana"
//           style={{
//             padding: "5%",
//             borderRadius: "25px",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         />
//         <CardContent>
//           <Typography
//             style={{ textAlign: "start", paddingLeft: "5%", color: "#00877c" }}
//           >
//             {props.heading1}
//           </Typography>
//           <Typography
//             gutterBottom
//             variant="h6"
//             component="div"
//             style={{ textAlign: "start", paddingLeft: "5%" }}
//           >
//             {props.head}
//           </Typography>
//           <Typography
//             variant="body2"
//             color="text.secondary"
//             style={{ textAlign: "start", paddingLeft: "5%" }}
//           >
//             {props.body}
//           </Typography>
//         </CardContent>
//       </CardActionArea>
//       <CardActions>
//         <Button
//           size="small"
//           color="primary"
//           style={{
//             textAlign: "start",
//             paddingLeft: "5%",
//             pointerEvents: "visible",
//           }}
//           onClick={() => openInNewTab(props.url)}
//         >
//           Read More..
//         </Button>
//       </CardActions>
//     </Card>
//   );
// }
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";

export default function MultiActionAreaCard(props) {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const url =
    "https://yourstory.com/herstory/2023/01/man-changing-face-women-cricket?utm_pageloadtype=scroll";
  return (
    <Card sx={{ maxWidth: 270, pointerEvents: "none" ,display:"grid",alignContent: "space-between"}}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="150"
          image={props.Img}
          alt="green iguana"
        />
        <CardContent>
          <Typography
            style={{ textAlign: "start", paddingLeft: "5%", color: "#00877c" }}
          >
            {props.heading1}
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            style={{ textAlign: "start", paddingLeft: "5%" }}
          >
            {props.head}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ textAlign: "start", paddingLeft: "5%" }}
          >
            {props.body}
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
      </CardActions> */}
      <CardActions>
        <Button
          size="small"
          color="primary"
          style={{
            textAlign: "start",
            paddingLeft: "5%",
            pointerEvents: "visible",
          }}
          onClick={() => openInNewTab(props.url)}
        >
          Read More..
        </Button>
      </CardActions>
    </Card>
  );
}
