import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IoMdArrowDropdown } from "react-icons/io";

export default function BasicMenu() {
  const url = "https://savemom.in/";
  const url1 = "https://allohms.com/";
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
      <div
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ color: "white" }}
      >
        Products
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            window.open("https://savemom.in/");
          }}
        >
          Savemom
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open("https://allohms.com");
          }}
        >
          AlloHMS
        </MenuItem>
        <MenuItem onClick={handleClose}>Allobaby</MenuItem>
        <MenuItem onClick={handleClose}>Allodoc</MenuItem>
      </Menu>
      <IoMdArrowDropdown  
      
      onClick={handleClick}
      onClose={handleClose}
      />
    </div>
  );
}
