import Navbar from "../Navbar";
import Img from "../img/achievementbanner.png";
import React from "react";
import Newscard from "../Newscard";
import Card from "../img/scaled2.jpg";
import { Grid, Button } from "@mui/material";
import Footer from "../Footer";
import Img1 from "../img/jiovioCEO.jpeg";
import Img2 from "../img/innovation.jpg";
import Img3 from "../img/image1.png";
import Img4 from "../img/2019_Healthcare_Pharmaceutical_AwardsLogo.png";
import Img5 from "../img/pregnancyimg.jpg";
import { useNavigate,useLocation } from "react-router-dom";


function News() {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location)

  return (
    <>
      <Navbar Imgbaner={Img} head="News & Reports" sub_content="Recognition in articles always give us great enthusiasm. If we are getting recognition for some good initiative that trigger us to do more. Below are things which driven us to move further.." />
      <h1 style={{ fontWeight: "100" }}>News</h1>
      <Grid
        container
        spacing={9}
        sx={{
          width: "80%",
          justifyContent: "center",
          marginTop: "50px",
          gap: "50px",
          marginLeft: "auto",
          marginRight: "auto",
        }}

      >
        <Newscard
          Img={Card}
          heading1="Startup"
          head="Startup turned to the gods to reach India’s rural women"
          body="Jiovio Healthcare had to get creative after data showed its maternity trackers were being used by husbands instead of pregnant wives..  "
          url="https://yourstory.com/2018/02/meet-experts-from-singapore-based-airmaker"
        />
        <Newscard
          Img={Img3}
          heading1="Allowear"
          head="IoTranic to save moms-to-be with its smart watch"
          body="JioVio Healthcare: Delivers Personalized and Predictive Maternal care using AI, IoT, and wearable technology!"
          url="https://www.coe-iot.com/blog/jiovio-healthcare-delivers-personalized-and-predictive-maternal-care-using-ai-iot-and-wearable-technology/"
        />
        <Newscard
          Img={Img4}
          heading1="2019 Healthcare Pharmaceutical Awards"
          head="Jiovio Healthcare"
          body="Best Pregnancy & Infant Care MedTech Company - India"
          url="https://www.ghp-news.com/winners/jiovio-healthcare/"
        />
        <Newscard
          Img={Img1}
          heading1="SKOCH Award Nominee"
          head="Excellence in Technology"
          body="Technology – Internet of Things (IoT)"
          url="https://exhibition.skoch.in/beacon-of-hope/jiovio-healthcare/"
        />
        <Newscard
          Img={Img5}
          heading1="COVID Monitoring"
          head="Saving Moms"
          body="From Saving Moms to COVID Monitoring, JioVio uses Tech for Good Health.."
          url="https://ysph.yale.edu/news-article/from-saving-moms-to-covid-monitoring-jiovio-uses-tech-for-good-health/"
        />
        <Newscard
          Img={Img2}
          heading1="Startup"
          head="Jiovio Healthcare"
          body="Technical Challenges & Milestone"
          url="https://timesnext.com/jiovio-healthcare-providing-personalized-maternal-care/"
        />
      </Grid>
      <br></br>
      <br></br>
      <Button variant="outlined" onClick={()=>{
        navigate('/News/List0fNews')
      }}>More News</Button>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15553.613284029063!2d77.67074600000001!3d12.946024!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae14053d832a8b%3A0x81127c230a6a533e!2sNASSCOM%2010000%20Startups%20Warehouse%20Bangalore!5e0!3m2!1sen!2sus!4v1678140831402!5m2!1sen!2sus"
        width="600"
        height="450"
        style={{ border: "0", width: "100%", marginTop: "50px" }}
      ></iframe>
      <a href="tel: +919344908937">
        <img
          src="https://jiovio.com/images/call.png"
          width={50}
          style={{
            position: "fixed",
            left: 20,
            bottom: 85,
          }}
        />
      </a>
      <a
        href="https://api.whatsapp.com/send?phone=+919344908937&text=Hi.,"
        target="_blank"
      >
        <img
          src="https://jiovio.com/images/whap.png"
          width={52}
          style={{
            position: "fixed",
            left: 20,
            marginTop: "100px",
            bottom: 20,
          }}
        />
      </a>
      <Footer />
    </>
  );
}
export default News;
