import { Button } from "@mui/material";
import React from "react";
import "./css/About.css";
import Img from "./img/1551692508.png";
import Imgdata from "./img/iconimage2.png";
import Grid from "@mui/material/Grid";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Card from "./Card";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ComputerIcon from "@mui/icons-material/Computer";

function About(props) {
  return (
    <div
      className="About"
      id="about"
      ref={props.refProp}
      style={{ pointerEvents: "none"}}
    >
      <div style={{ width: "fit-content", margin: "auto auto" ,visibility:"hidden"}} >
        <h1 style={{ marginBottom: "0", fontWeight: "lighter" }}>What We Do</h1>
        {/* <hr style={{ width: "60%", border: "1px solid #F06070" }} /> */}
      </div>
      <h1>
        <div style={{ width: "fit-content", margin: "auto auto" }}>
          <h4 style={{ marginBottom: "0", fontWeight: "lighter" }}>
            What We Do
          </h4>
          <hr style={{ width: "60%", border: "1px solid #F06070" }} />
        </div>
      </h1>
      <div>
        <div className="Contain2">
          <img
            src={Img}
            height="60px"
            style={{ marginLeft: "auto", marginRight: "auto" }}
          />
          <p style={{ color: "gray" }}>
            "Healthy individuals” and that’s our aim. To have physically and
            mentally healthy citizens has been our goal to be achieved. Through
            our jiovio products, we concentrate more on health care for ordinary
            people.
          </p>
        </div>
      </div>
      <div className="Contain3" >
        <img
          src={Imgdata}
          height="60px"
          style={{ marginRight: "auto", marginLeft: "auto" }}
        />
        <p style={{ color: "gray" }}>
          Every individual will be treated medically and the entire medical
          process is fastened.
        </p>
      </div>
      <h2 style={{ fontWeight: "100" }}>
        <b> Portable Home Healthcare Device</b>
      </h2>
      <p style={{ fontSize: "0.9em", color: "gray" ,marginBottom:"100px"}}>
        It Monitors Blood Oxygen + Blood Glucose + Body Temperature + ECG + Many
        more
      </p>
      {/* <Button
        variant="contained"
        style={{ marginTop: "50px", backgroundColor: "#ce357d" }}
      >
        Learn More
      </Button> */}
      <Grid
        container
        spacing={9}
        sx={{
          justifyContent: "center",
          backgroundColor: "#f16070",
          marginTop: "50px",
        }}
      >
        <Card
          Icone={<ComputerIcon />}
          sub="High Quality Cost"
          content="Effective Maternal Health Care, IOT Devices, Telemedicine software"
          color="white"
        />
        <Card
          Icone={<FavoriteIcon />}
          sub="Health Education"
          content="WHO Recommendations, Local/Regional Language info-graphics"
          color="white"
        />
        <Card
          Icone={<TrendingUpIcon />}
          sub="Data Gathering and Analysis"
          content="Data matrix of rural women and children for governments and foundations"
          color="white"
        />
      </Grid>
    </div>
  );
}

export default About;
