import * as React from "react";
import About from "../About";
import Navbar from "../Navbar";
import Products from "../Products";
import Footer from "../Footer";
import Img from "../img/pregnancyimg.jpg";
import ImageSlider from "../Carousel";
function Dashboard() {
  var myRef = React.useRef();

  const executeScroll = () => myRef.current.scrollIntoView();
  return (
    <>
      <Navbar
        scrollRef={executeScroll}
        Imgbaner={Img}
        head="EVERY 2 MINUTES A WOMEN DIES GIVING BIRTH"
      />
      <About />
      <Products />
      <ImageSlider />
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15553.613284029063!2d77.67074600000001!3d12.946024!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae14053d832a8b%3A0x81127c230a6a533e!2sNASSCOM%2010000%20Startups%20Warehouse%20Bangalore!5e0!3m2!1sen!2sus!4v1678140831402!5m2!1sen!2sus"
        width="600"
        height="450"
        style={{ border: "0", width: "100%", marginTop: "50px" }}
        id="map"
      ></iframe>
      <a href="tel: +919344908937">
        <img
          src="https://jiovio.com/images/call.png"
          width={50}
          style={{
            position: "fixed",
            left: 20,
            bottom: 85,
          }}
        />
      </a>
      <a
        href="https://api.whatsapp.com/send?phone=+919344908937&text=Hi.,"
        target="_blank"
      >
        <img
          src="https://jiovio.com/images/whap.png"
          width={52}
          style={{
            position: "fixed",
            left: 20,
            marginTop: "100px",
            bottom: 20,
          }}
        />
      </a>

      <Footer refProp={myRef} />
    </>
  );
}
export default Dashboard;
