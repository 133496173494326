import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "./Cardnews";
import TimelineIcon from "@mui/icons-material/Timeline";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageSlider = () => {
  const navigate = useNavigate();
  const buttonStyle = {
    color: "#F16171",
    border: "2px solid #F16171",
    background: "transparent",
    padding: "5px 20px",
    marginTop: "50px",
    transition: "all 0.3s ease",
    "&:hover": {
      background: "#F16171",
      color: "white",
    },
  };

  const sliderRef = useRef(null); // Create a ref for the Slider component

  useEffect(() => {
    // Use a timer to advance the slider every 2 seconds
    const sliderInterval = setInterval(() => {
      if (sliderRef.current) {
        sliderRef.current.slickNext(); // Go to the next slide
      }
    }, 60000); // 2000ms = 2 seconds

    return () => {
      clearInterval(sliderInterval); // Clear the timer when the component unmounts
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 2500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 0, // Set this to 0 to disable the built-in autoplay
    responsive: [
      {
        breakpoint: 1396,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 934,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };


  return (
    <div style={{ width: "75%", marginLeft: "auto", marginRight: "auto" }}>
      <h2 style={{ fontWeight: 100, padding: "2pc" }}>News</h2>
      <Slider {...settings} style={{ marginLeft: "7%" }} dots={false}>
        <div className="card">
          <Card
            Icone={<NewspaperIcon sx={{ fontSize: "35px" }} />}
            sub="These 10 startups from Code for the Next Billon's "
            content="The Code for the Next Offon programme, a joint initiative by Facebook and NASSCOM 10,000 Startups, wow launched in 2015."
            bgcolor="#CD347C"
            ImgDis="YourStory"
            url="https://yourstory.com/2016/07/iotranic"
          />
        </div>
        <div className="card">
          <Card
            Icone={<NewspaperIcon sx={{ fontSize: "35px" }} />}
            sub="Uber announces its revamped program for start-ups called Uber EXCHANGE"
            content="In a breakthrough announcement propagating the advent of start-ups in India."
            bgcolor="#F16171"
            ImgDis="News Mobile"
            url="https://newsmobile.in/articles/2017/12/01/803686/"
          />
        </div>
        <div>
          <Card
            Icone={<NewspaperIcon sx={{ fontSize: "35px" }} />}
            sub="To the Womb of Real India"
            content="Jiovio is a Singapore-based startup, with two PSG Tech alumni co-founders-Divya Krishnan (BECSE 2009-2013) and Sunder Jagannathon (BE Automotive 2008-2013) "
            bgcolor="#e1428d"
            ImgDis="The Bridge"
            url="https://thebridge.psgtech.ac.in/?p=5493"
          />
        </div>
        <div className="card">
          <Card
            Icone={<NewspaperIcon sx={{ fontSize: "35px" }} />}
            sub="Mommt Tracker"
            content="Jiovio hornecare kit is an ideal companion for pregnant women"
            bgcolor="#e1428d"
            ImgDis="OutLock Business"
            url="https://www.outlookbusiness.com/amp/enterprise/big-idea/mommy-tracker-4083"
          />
        </div>
        <div className="card">
          <Card
            Icone={<NewspaperIcon sx={{ fontSize: "35px" }} />}
            ImgDis="Winner 2018"
            content="savemom"
            bgcolor="#F16171"
            sub="Billionth award winner for most impactful innovation in South east "
            url="http://www.mbillionth.in/winner-2018/"
          />
        </div>
        <div className="card">
          <Card
            Icone={<TimelineIcon sx={{ fontSize: "35px" }} />}
            sub="Winner of 2017"
            content="Congratulations to Jiovio Healthcare for winning the 2017 Code for the Next Billion Grand Prize!"
            bgcolor="#e1428d"
            ImgDis="Grand Award Winner"
            url="https://twitter.com/MADevImpact/status/957869737920229378"
          />
        </div>
      </Slider>
      <Button
        sx={buttonStyle}
        onClick={() => {
          navigate("/News/List0fNews");
          const target = document.querySelector("#navbar");
          if (target) {
            target.scrollIntoView({ behavior: "smooth" });
          }
        }}
      >
        Read more
      </Button>
    </div>
  );
};

export default ImageSlider;
