// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDBHDW8-Ip3ZreRtZIssZVg2ALCUxpw_XU",
  authDomain: "jiovio-7d0a2.firebaseapp.com",
  projectId: "jiovio-7d0a2",
  storageBucket: "jiovio-7d0a2.appspot.com",
  messagingSenderId: "483560975024",
  appId: "1:483560975024:web:e6a9eb7416e22211f2e975",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
// export const batabase=app.firestote()
// const users = await db().collection('Contact_data').get();
// console.log()
