import Grid from "@mui/material/Grid";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import IconButton from "@mui/material/IconButton";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link as RouterLink } from "react-router-dom";
import React, { useRef, useState } from "react";
import { Typography, Link, Box } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// import emailjs from '@emailjs/browser';
import { db } from "./firebase";
import { collection, addDoc, getDocs } from "firebase/firestore";
function Footer(props) {
  const handleClick = () => {
    window.open("https://www.facebook.com/jioviohealthcare/", "_blank");
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      phone_no: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .max(50)
        .required("Email is required"),
      phone_no: Yup.string()
        .max(10)
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Phone number is not valid"
        ),
    }),
  });
  const [myArray, setMyArray] = useState([]);
  const [myArr, setMyArr] = useState([]);
  const addData = async (values) => {
    if (myArray[0] !== values.email) {
      if (myArr[0] !== values.phone_no) {
        try {
          const docRef = await addDoc(collection(db, "Contact_data"), values);
          formik.resetForm({ values: null });
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      } else {
        alert("phone is there");
      }
    } else {
      alert("Gmail is there");
    }
  };
  const getData = async (values, e) => {
    const querySnapshot = await getDocs(collection(db, "Contact_data"));
    querySnapshot.forEach((doc) => {
      if (doc.data().email === values.email) {
        myArray.push(doc.data().email);
        console.log(doc.data().email === values.email);
      }
      if (doc.data().phone_no === values.phone_no) {
        myArr.push(doc.data().phone_no);
        console.log(doc.data().phone_no === values.phone_no);
      }
    });
    addData(values);
  };

  return (
    <div ref={props.refProp} id="contact">
      <Grid
        container
        spacing={0}
        sx={{ justifyContent: "center", marginTop: "20px", gap: "10%" }}
      >
        <div
          style={{ fontWeight: "lighter", width: "18pc", textAlign: "start" }}
        >
          <h1 style={{ fontWeight: "lighter" }}>Contact Info</h1>
          <h3 style={{ fontWeight: "lighter" }}>Madurai Address</h3>
          <p style={{ color: "gray" }}>
            38, First Floor, Arunachala Arcade,<br></br> Tpk Road, Pasumalai,
            Madurai,<br></br>
            Tamil Nadu 625004.
          </p>
          <h3 style={{ fontWeight: "lighter" }}>Bengaluru Address</h3>
          <p style={{ color: "gray" }}>
            Lower Ground Floor, DD3, Diamond
            <br /> District ISRO Colony, Domlur, Bengaluru,<br></br> Karnataka
            560008
          </p>
          <h3 style={{ fontWeight: "lighter" }}>Singapore Address</h3>
          <p style={{ color: "gray" }}>
            No: 8 Burn Road,<br></br> #12-05 Trivex,<br></br> Singapore 369977.
          </p>
        </div>
        <div style={{ textAlign: "start" }}>
          <h1 style={{ fontWeight: "lighter" }}>Join Us</h1>
          <div>
            <IconButton id="IconButton">
              <FacebookIcon onClick={handleClick} />
            </IconButton>
            <IconButton id="IconButton">
              <LinkedInIcon
                onClick={() => {
                  window.open(
                    "https://www.linkedin.com/company/jiovio/mycompany/",
                    "_blank"
                  );
                }}
              />
            </IconButton>
            <IconButton id="IconButton">
              <TwitterIcon
                onClick={() => {
                  window.open("https://twitter.com/jio_vio", "_blank");
                }}
              />
            </IconButton>
            <IconButton id="IconButton">
              <InstagramIcon
                onClick={() => {
                  window.open(
                    "https://www.instagram.com/jiovio_healthcare/",
                    "_blank"
                  );
                }}
              />
            </IconButton>
            <IconButton id="IconButton">
              <YouTubeIcon
                onClick={() => {
                  window.open(
                    "https://www.youtube.com/channel/UCQHoHxfqc62-pAv9a4XTcJA/featured",
                    "_blank"
                  );
                }}
              />
            </IconButton>
          </div>
          <div style={{ margin: "20px 0px 7px 0px" }}>
            <iframe
              src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fjioviohealthcare&layout=button_count&action=like&size=large&share=true&appId"
              style={{ border: "none", overflow: "hidden", width: "250px" }}
              height={30}
            ></iframe>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              cursor: "pointer",
              padding: "10",
            }}
            onClick={() => {
              window.open("tel:+91 9361513147");
            }}
          >
            <LocalPhoneIcon
              style={{
                backgroundColor: "#393939",
                color: "white",
                padding: "6px",
                borderRadius: "50px",
                width: "30px",
                height: "30px",
              }}
            />
            <h5> +91 7667878400</h5>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              margin: "0",
            }}
          >
            <EmailIcon
              style={{
                backgroundColor: "#393939",
                color: "white",
                padding: "6px",
                borderRadius: "50px",
                width: "30px",
                height: "30px",
              }}
            />
            <h5
              style={{ margin: "0", cursor: "pointer" }}
              onClick={() => {
                window.open(
                  "https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=info@jiovio.com"
                );
              }}
            >
              info@jiovio.com
            </h5>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              margin: "0",
              marginTop: "16px",
            }}
          >
            <EmailIcon
              style={{
                backgroundColor: "#393939",
                color: "white",
                padding: "6px",
                borderRadius: "50px",
                width: "30px",
                height: "30px",
              }}
            />
            <h5
              style={{ margin: "0", cursor: "pointer" }}
              onClick={() => {
                window.open(
                  "https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=hr@jiovio.com"
                );
              }}
            >
              {" "}
              hr@jiovio.com
            </h5>
          </div>
        </div>
        <form style={{ marginBottom: "40px" }}>
          <h1 style={{ fontWeight: "lighter" }}>Contact Form</h1>
          <Grid
            container
            sx={{
              justifyContent: "center",
              marginTop: "20px",
              gap: "35px",
              marginBottom: "40px",
            }}
          >
            <TextField
              id="standard-basic"
              label="Name"
              variant="standard"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              name="name"
            />
            <TextField
              id="standard-basic"
              label="Mobile"
              variant="standard"
              error={Boolean(formik.touched.phone_no && formik.errors.phone_no)}
              helperText={formik.touched.phone_no && formik.errors.phone_no}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.phone_no}
              name="phone_no"
            />
          </Grid>
          <Grid
            container
            sx={{
              justifyContent: "center",
              marginTop: "20px",
              gap: "22px",
              marginBottom: "40px",
            }}
          >
            <TextField
              id="standard-basic"
              label="Email"
              variant="standard"
              error={Boolean(formik.touched.email && formik.errors.email)}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              helperText={formik.touched.email && formik.errors.email}
              name="email"
            />
            <TextField
              id="standard-multiline-flexible"
              label="Message"
              multiline
              maxRows={2}
              variant="standard"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.message}
              name="message"
              sx={{
                marginLeft: "9px",
              }}
            />
          </Grid>
          <Button
            variant="contained"
            style={{ marginTop: "50px", backgroundColor: "#F16171" }}
            onClick={() => {
              getData(formik.values);
            }}
          >
            Send Message
          </Button>
        </form>
      </Grid>
      <Box
        sx={{
          width: "100%",
          textAlign: "end",
          backgroundColor: "gray",
          height: "1px",
        }}
      >
        <button
          style={{
            backgroundColor: "#F16071",
            marginRight: "5%",
            marginTop: -100,
            border: "none",
            padding: "5px 8px 5px 8px",
            borderRadius: "5px",
          }}
          onClick={() => {
            const target = document.querySelector("#navbar");
            if (target) {
              target.scrollIntoView({ behavior: "smooth" });
            }
          }}
        >
          <ExpandLessIcon sx={{ color: "white" }} />
        </button>
      </Box>
      <Grid
        sx={{ bgcolor: "#F8F8F8", py: 2, marginTop: "10px" }}
        container
        direction="row"
        justifyContent="start"
        alignItems="center"
        padding={"0 15%"}
        gap={"5%"}
        color={"#979797"}
      >
        <Typography variant="body2" align="center">
          © 2023 Jiovio Healthcare Pvt. Ltd. All rights reserved. |{" "}
          <Link
            sx={{ color: "inherit" }}
            onClick={() => {
              const target = document.querySelector("#map");
              if (target) {
                target.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Sitemap{" "}
          </Link>
        </Typography>
        <Typography variant="body2" align="center">
          <Link
            component={RouterLink}
            to="/"
            color="inherit"
            underline="hover"
            onClick={() => {
              const target = document.querySelector("#about");
              if (target) {
                target.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            About
          </Link>
          {" | "}
          <a
            href="https://savemom.in/"
            target="_blank"
            style={{ color: "#979797", textDecoration: "none" }}
            onClick={() => {
              const target = document.querySelector("#navbar");
              if (target) {
                target.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            {/* <Link
              component={RouterLink}
              color="inherit"
              onClick={() => {
                const target = document.querySelector("#navbar");
                if (target) {
                  target.scrollIntoView({ behavior: "smooth" });
                }
              }}
            > */}
            Products
            {/* </Link> */}
          </a>
          {" | "}
          <Link
            component={RouterLink}
            to="/news"
            color="inherit"
            underline="hover"
            onClick={() => {
              const target = document.querySelector("#navbar");
              if (target) {
                target.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            News
          </Link>
          {" | "}
          <Link
            component={RouterLink}
            to="/Awards"
            color="inherit"
            underline="hover"
            onClick={() => {
              const target = document.querySelector("#navbar");
              if (target) {
                target.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Awards
          </Link>
          {" | "}
          <Link
            href="https://jioviohealthcare.medium.com/"
            target="_blank"
            style={{
              textDecoration: "none",
              outline: "none",
              color: "#979797",
            }}
          >
            Blog
          </Link>
          {" | "}
          <Link
            component={RouterLink}
            to="/"
            color="inherit"
            underline="hover"
            onClick={() => {
              const target = document.querySelector("#contact");
              if (target) {
                target.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Contact
          </Link>
        </Typography>
        {/* <Typography variant="body2" align="center">
          Made with ❤️ by{" "}
          <Link
            href="https://github.com/Aribaskar-jb"
            target="_blank"
            style={{ textDecoration: "none" }}
            rel="noopener"
          >
            Aribaskar-jb
          </Link>
        </Typography> */}
      </Grid>
    </div>
  );
}
export default Footer;
