import Navbar from "../Navbar";
import Img from "../img/achievementbanner.png";
import React from "react";
import {Button } from "@mui/material";
import Footer from "../Footer";

function Award() {
  return (
    <>
      <Navbar Imgbaner={Img} head="Awards" />
      <h1 style={{ fontWeight: "100" }}>Awards</h1>

      <Button variant="outlined">More News</Button>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15553.613284029063!2d77.67074600000001!3d12.946024!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae14053d832a8b%3A0x81127c230a6a533e!2sNASSCOM%2010000%20Startups%20Warehouse%20Bangalore!5e0!3m2!1sen!2sus!4v1678140831402!5m2!1sen!2sus"
        width="600"
        height="450"
        style={{ border: "0", width: "100%", marginTop: "50px" }}
      ></iframe>
      <Footer />
    </>
  );
}
export default Award;
