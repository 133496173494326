import * as React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Button } from "@mui/material";
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

export default function CardList(props) {
  console.log(props.item);
  return (
    <div id="ListDiv">
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "100pc",
          "@media (min-width: 600px)": {
            padding: "40px",
          },
          "@media (max-width: 1496px)": {
            width: "100%",
          },
        }}
      >
        <Grid item xs={12} sm={6} md={5} lg={4} xl={3}>
          <Img alt="complex" src={props.item.img} style={{ borderRadius: "10px" ,width:"20pc",height:"14pc"}} />
        </Grid>
        <Grid item xs={12} sm={6} md={7} lg={8} xl={9}>
          <div style={{ textAlign: "start", width: "100%" }}>
            <h4 style={{ color: "#FF626F", margin: 0 }}>{props.item.title}</h4>
            <h2>{props.item.heading}</h2>
            <p style={{ color: "gray" }}>{props.item.sub_content}</p>
            <a href={props.item.url} target="_blanck">
              <Button
                size="small"
                color="primary"
                style={{
                  pointerEvents: "visible",
                }}
              >
                Read More..
              </Button>
            </a>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
