// import * as React from 'react';
// import CardContent from '@mui/material/CardContent';
// import Typography from '@mui/material/Typography';
// import { CardActionArea,Grid } from '@mui/material';

// export default function ActionAreaCard(props) {
//   return (
//     <a
//     href={props.url}
//     style={{ textDecoration: "none" ,color:"black"}}
//     target="_blank"
//   >
//     <Grid item xs="auto"  style={{display:"grid",justifyContent:"center"}} >
//         <div sx={{ backgroundColor:"transparent" }}  className='card_data' >
//           <CardActionArea style={{border:"1px solid gray",height:"22pc",borderRadius:"15px"}} >
//             <div style={{backgroundColor:props.bgcolor,height:"10pc",display:"grid",alignItems:"center",borderRadius:"15px"}}>
//               <div style={{width:"fit-content",padding:"20px 20px",margin:"10px auto",borderRadius:"50px"}}>
//                   {props.Icone}
//               </div>
//               <h1  style={{ fontWeight: "100",margin:"0",marginBottom:"30px",textAlign:"center"}}>{props.ImgDis}</h1>
//             </div>
//             <CardContent style={{display:"grid"}}>
//               <Typography gutterBottom variant="h6" component="div" color={props.color}>
//                 {props.sub}
//               </Typography>
//               <Typography variant="body2" color="gray">
//               {props.content}
//               </Typography>
//             </CardContent>
//           </CardActionArea>
//         </div>
//  </Grid >
//  </a>
//   );
// }
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import { Avatar } from "@mui/material";
import NewspaperIcon from "@mui/icons-material/Newspaper";

export default function ActionAreaCard(props) {
  return (
    <a
      href={props.url}
      style={{ textDecoration: "none", color: "black" }}
      target="_blank"
    >
      <Card sx={{ maxWidth: 320,height:"21pc" }} >
        <CardHeader
          sx={{
            backgroundColor: props.bgcolor,
            display: "grid",
            placeItems: "center",
          }}
          avatar={
            <div style={{display:"grid"}}>
              <Avatar
                style={{ margin: "auto", backgroundColor: "transparent" }}
              >
                {props.Icone}
              </Avatar>
              <h2 style={{ marginLeft: "5px", fontWeight: "100" ,color:"white"}}>
                {props.ImgDis}
              </h2>
            </div>
          }
        />
        <CardActionArea >
          <CardContent >
            <Typography gutterBottom variant="h6" component="div">
              {props.sub}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {props.content}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </a>
  );
}
