import Navbar from "../Navbar";
import Img from "../img/achievementbanner.png";
import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Footer from "../Footer";
import Grid from "@mui/material/Grid";
import CardList from "../Card_List";
import Data from "../data.json";
import { useState } from "react";
function NewsList() {
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Data.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  return (
    <>
      <Navbar
        Imgbaner={Img}
        head="News & Reports"
        sub_content="Recognition in articles always give us great enthusiasm. If we are getting recognition for some good initiative that triggers us to do more. Below are things which driven us to move further.."
      />
      <Grid
        container
        spacing={9}
        sx={{
          justifyContent: "center",
          marginTop: "50px",
          alignItems: "center",
        }}
      >
        {currentItems.map((item) => {
          return <CardList item={item} />;
        })}
        {/* <CardList items={currentItems} /> */}
      </Grid>
      <Stack spacing={2} sx={{ alignItems: "center" }}>
        <Pagination
          count={Math.ceil(Data.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          sx={{
            "& .MuiPaginationItem-root": {
              color: "#F06070",
            },
            "& .MuiPaginationItem-page.Mui-selected": {
              backgroundColor: "#F06070",
              color: "white",
            },
          }}
        />
      </Stack>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15553.613284029063!2d77.67074600000001!3d12.946024!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae14053d832a8b%3A0x81127c230a6a533e!2sNASSCOM%2010000%20Startups%20Warehouse%20Bangalore!5e0!3m2!1sen!2sus!4v1678140831402!5m2!1sen!2sus"
        width="600"
        height="450"
        style={{ border: "0", width: "100%", marginTop: "50px" }}
      ></iframe>
      <a href="tel: +919344908937">
        <img
          src="https://jiovio.com/images/call.png"
          width={50}
          style={{
            position: "fixed",
            left: 20,
            bottom: 85,
          }}
        />
      </a>
      <a
        href="https://api.whatsapp.com/send?phone=+919344908937&text=Hi.,"
        target="_blank"
      >
        <img
          src="https://jiovio.com/images/whap.png"
          width={52}
          style={{
            position: "fixed",
            left: 20,
            marginTop: "100px",
            bottom: 20,
          }}
        />
      </a>
      <Footer />
    </>
  );
}
export default NewsList;
