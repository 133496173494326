import "./App.css";
import { Await, BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./components/pages/Dashboard";
import News from "./components/pages/News";
import Awards from "./components/pages/Awards";
import NewsList from "./components/pages/NewsList";
import ScrollToTop from "./components/pages/ScrollToTop"
function App() {
  var Tawk_API = Tawk_API || {},
    Tawk_LoadStart = new Date();
  (function () {
    var s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/64f4b855b2d3e13950edae07/1h9dv042v";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
  })();
  
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Dashboard />}></Route>
          <Route path="/News" element={<News />}></Route>
          <Route path="/News/List0fNews" element={<NewsList />} />
          <Route path="/Awards" element={<Awards />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
