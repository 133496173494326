import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Grid } from "@mui/material";
import "./css/Productscard.css";

export default function Productscard(props) {
  const url = props.url;
  return (
    <Grid item xs="auto" style={{ paddingTop: "10px" }}>
      <a href={url} style={{ textDecoration: "none" }} target="_blank">
        <Card
          sx={{ maxWidth: 345, width: "16pc", height: "29pc " }}
          className="Card"
        >
          <CardActionArea>
            <CardMedia
              component="img"
              height="140"
              image={props.Img}
              alt="green iguana"
            />
            <CardContent style={{ textAlign: "start", paddingLeft: "35px" }}>
              <Typography gutterBottom variant="h6" component="div">
                {props.heading}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ width: "85%" }}
              >
                {props.body}
              </Typography>
            </CardContent>
            {props.button}
          </CardActionArea>
        </Card>
      </a>
    </Grid>
  );
}
