import * as React from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Img from "./img/jiovio_logo_update.png";
import "./css/Navbar.css";
import Menu from "./Menu";
import Slider from "../Slider";

const drawerWidth = 240;

export default function DrawerAppBar(props) {
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    // <Box
    //   sx={{
    //     textAlign: "center",
    //     backgroundColor: "rgb(241, 96, 113)",
    //     color: "white",
    //     height: "100%",
    //   }}
    // >
    //   <Typography variant="h6" sx={{ my: 2 }}>
    // <img
    //   src={Img}
    //   height="70px"
    //   style={{ filter: "drop-shadow(0px 5px 3px rgb(40, 40, 40))" }}
    // />
    //   </Typography>
    //   <Divider />
    //   <List style={{ display: { sm: "block", md: "none", xs: "block" } }}>
    //     <ListItem disablePadding>
    // <ListItemButton
    //   sx={{ textAlign: "center" }}
    //   onClick={handleDrawerToggle}
    // >
    //   <ListItemText primary={"About"} />
    // </ListItemButton>
    //     </ListItem>
    //     <ListItem disablePadding>
    //       <ListItemButton sx={{ textAlign: "center" }}>
    //         <ListItemText primary={<Menu />} />
    //       </ListItemButton>
    //     </ListItem>
    //     <ListItem disablePadding>
    //       <ListItemButton
    //         sx={{ textAlign: "center" }}
    //         onClick={() => navigate("/news")}
    //       >
    //         <ListItemText primary={"News"} />
    //       </ListItemButton>
    //     </ListItem>
    //     <ListItem disablePadding>
    //       <ListItemButton
    //         sx={{ textAlign: "center" }}
    //         onClick={handleDrawerToggle}
    //       >
    //         <ListItemText primary={"Awards"} />
    //       </ListItemButton>
    //     </ListItem>
    //     <ListItem disablePadding>
    //       <ListItemButton
    //         sx={{ textAlign: "center" }}
    //         onClick={handleDrawerToggle}
    //       >
    //         <ListItemText primary={"Blog"} />
    //       </ListItemButton>
    //     </ListItem>
    //     <ListItem disablePadding>
    //       <ListItemButton
    //         sx={{ textAlign: "center" }}
    //         onClick={props.scrollRef}
    //       >
    //         <ListItemText primary={"Contact"} />
    //       </ListItemButton>
    //     </ListItem>
    //   </List>
    // </Box>
    <Slider Img={Img} scrollRef={props.scrollRef} event={handleDrawerToggle} />
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const url = "https://jioviohealthcare.medium.com/";
  const handleClick = () => {
    console.log("hi this is aribaskar");
  };
  return (
    <Box sx={{ display: "flex" }} id="navbar">
      <CssBaseline />
      <AppBar component="nav" style={{ backgroundColor: "#f16071" }}>
        <div style={{ marginLeft: "auto", marginRight: "auto", width: "80%" }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "block", md: "none", xs: "block" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div">
              <img
                src={Img}
                height="70px"
                style={{ filter: "drop-shadow(0px 5px 3px rgb(40, 40, 40))" }}
              />
            </Typography>
            <Box
              sx={{
                display: { sm: "none", md: "block", xs: "none" },
                marginLeft: "auto",
              }}
            >
              <Button
                sx={{
                  color: "#fff",
                  marginRight: "5px",
                  textTransform: "none",
                  fontSize: "1rem",
                  textTransform: "none",
                  fontSize: "1rem",
                }}
                onClick={() => {
                  const target = document.querySelector("#about");
                  if (target) {
                    target.scrollIntoView({ behavior: "smooth" });
                  }
                  navigate("/");
                }}
              >
                About
              </Button>
              <Button
                sx={{
                  color: "#fff",
                  marginRight: "5px",
                  textTransform: "none",
                  fontSize: "1rem",
                }}
              >
                <Menu />
              </Button>
              <Button
                sx={{
                  color: "#fff",
                  marginRight: "5px",
                  textTransform: "none",
                  fontSize: "1rem",
                }}
                onClick={() => {
                  const target = document.querySelector("#navbar");
                  if (target) {
                    target.scrollIntoView({ behavior: "smooth" });
                  }
                  navigate("/news");
                }}
              >
                News
              </Button>
              <Button
                sx={{
                  color: "#fff",
                  marginRight: "5px",
                  textTransform: "none",
                  fontSize: "1rem",
                }}
                onClick={() => navigate("/Awards")}
              >
                Awards
              </Button>
              <a
                href="https://jioviohealthcare.medium.com/"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <Button
                  sx={{
                    color: "#fff",
                    marginRight: "5px",
                    textTransform: "none",
                    fontSize: "1rem",
                  }}
                  onClick={handleClick}
                >
                  Blog
                </Button>
              </a>
              <Button
                sx={{
                  color: "#fff",
                  marginRight: "5px",
                  textTransform: "none",
                  fontSize: "1rem",
                }}
                // onClick={props.scrollRef}
                onClick={() => {
                  const target = document.querySelector("#contact");
                  if (target) {
                    target.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              >
                Contact
              </Button>
            </Box>
          </Toolbar>
        </div>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: "block", md: "none", xs: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        id={props.sub_content ? "nav_img" : "firstblockimg"}
        sx={{ backgroundImage: `url(${props.Imgbaner})` }}
      >
        <div style={{ width: "50%", padding: "80px 5%", textAlign: "start" }}>
          <h1 id={props.sub_content ? "nav_heading" : "heading"}>
            {props.head}
          </h1>
          <br></br>
          {props.sub_content ? (
            <h4 id="sub_heading">{props.sub_content}</h4>
          ) : (
            props.head != "Awards" && (
              <a
                id="watchnow"
                href="https://www.youtube.com/embed/lIEm7xuGels "
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "white",
                  border: "1px solid black",
                  borderColor: "white",
                  borderRadius: "5px",
                }}
              >
                WATCH NOW
              </a>
            )
          )}
        </div>
      </Box>
    </Box>
  );
}
